var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Manage reports")]),
      _vm.loadingReportSchedules
        ? _c("ck-loader")
        : _vm._l(_vm.reportTypes, function(reportType, index) {
            return _c(
              "section",
              { key: reportType.type },
              [
                _c(
                  "gov-inset-text",
                  [
                    _c("gov-heading", { attrs: { size: "m" } }, [
                      _vm._v(_vm._s(reportType.type))
                    ]),
                    _c("gov-body", [_vm._v(_vm._s(reportType.description))]),
                    _c("gov-heading", { attrs: { size: "s" } }, [
                      _vm._v("Scheduled generation")
                    ]),
                    _c("gov-body", [
                      _vm._v(
                        "This decides the regularity of when reports are sent to\n        you."
                      )
                    ]),
                    _c("ck-radio-input", {
                      attrs: {
                        id: "repeat_type[" + reportType.type + "]",
                        error: reportType.scheduleForm.$errors.get(
                          "repeat_type"
                        ),
                        options: _vm.repeatTypeOptions
                      },
                      on: {
                        input: function($event) {
                          return reportType.scheduleForm.$errors.clear(
                            "repeat_type"
                          )
                        }
                      },
                      model: {
                        value: reportType.scheduleForm.repeat_type,
                        callback: function($$v) {
                          _vm.$set(reportType.scheduleForm, "repeat_type", $$v)
                        },
                        expression: "reportType.scheduleForm.repeat_type"
                      }
                    }),
                    !reportType.scheduleForm.$submitting
                      ? _c(
                          "gov-button",
                          {
                            attrs: { type: "submit" },
                            on: {
                              click: function($event) {
                                return _vm.onSaveReportSchedule(reportType)
                              }
                            }
                          },
                          [_vm._v("Save frequency")]
                        )
                      : _c(
                          "gov-button",
                          { attrs: { type: "submit", disabled: "" } },
                          [_vm._v("Saving...")]
                        ),
                    _c("gov-heading", { attrs: { size: "s" } }, [
                      _vm._v("Generate a report now")
                    ]),
                    _c("gov-body", [
                      _vm._v(
                        "This allows you to generate a one off report which will begin\n        downloading immediately."
                      )
                    ]),
                    _vm.acceptsDateRange(reportType)
                      ? [
                          _c("ck-date-input", {
                            attrs: {
                              id: "starts_at[" + reportType.type + "]",
                              error: reportType.generateForm.$errors.get(
                                "starts_at"
                              ),
                              label: "From date"
                            },
                            on: {
                              input: function($event) {
                                return reportType.generateForm.$errors.clear(
                                  "starts_at"
                                )
                              }
                            },
                            model: {
                              value: reportType.generateForm.starts_at,
                              callback: function($$v) {
                                _vm.$set(
                                  reportType.generateForm,
                                  "starts_at",
                                  $$v
                                )
                              },
                              expression: "reportType.generateForm.starts_at"
                            }
                          }),
                          _c("ck-date-input", {
                            attrs: {
                              id: "ends_at[" + reportType.type + "]",
                              error: reportType.generateForm.$errors.get(
                                "ends_at"
                              ),
                              label: "To date"
                            },
                            on: {
                              input: function($event) {
                                return reportType.generateForm.$errors.clear(
                                  "ends_at"
                                )
                              }
                            },
                            model: {
                              value: reportType.generateForm.ends_at,
                              callback: function($$v) {
                                _vm.$set(
                                  reportType.generateForm,
                                  "ends_at",
                                  $$v
                                )
                              },
                              expression: "reportType.generateForm.ends_at"
                            }
                          })
                        ]
                      : _vm._e(),
                    !reportType.generateForm.$submitting
                      ? _c(
                          "gov-button",
                          {
                            attrs: { type: "submit" },
                            on: {
                              click: function($event) {
                                return _vm.onGenerate(reportType)
                              }
                            }
                          },
                          [_vm._v("Generate and download")]
                        )
                      : _c(
                          "gov-button",
                          { attrs: { type: "submit", disabled: "" } },
                          [_vm._v("Generating...")]
                        )
                  ],
                  2
                ),
                index < _vm.reportTypes.length - 1
                  ? _c("gov-section-break", {
                      attrs: { size: "l", visible: "" }
                    })
                  : _vm._e()
              ],
              1
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }